import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../font/style.css'

class IndexPage extends React.Component {

  state = {
    registered: false,
    name: "",
    email: "",
    pass: "",
    passRepeat: "",
    domain: "",
    issue: null,
    registering: false
  }

  componentDidMount(){
    if(typeof window != "undefined"){
      window.Paddle.Setup({
        vendor: 106664,
        eventCallback: (data) => {
          console.log("paddle is working", data.event);
          if(data.event == "Checkout.Close"){
            this.setState({registering: false, issue: "The payment popup was closed unexpectedly, please try again or contact us"});

            if(typeof window != "undefined"){
              window.scrollTo(0, 0);
            }
          }

          if(data.event == "Checkout.Complete"){

            this.setState({registered: true});
          }
        }
      });
    }
  }

  getCalls(plan){
    switch (plan) {
      case "starter":
        return "5,000";
        break;
      case "maker":
        return "50,000";
        break;
      case "professional":
        return "500,000";
        break;
      default:

    }
  }

  getPlanId(plan){
    if(plan == "starter"){
      return 580889;
    }else if(plan == "maker"){
      return 580882;
    }else if(plan == "professional"){
      return 580884;
    }
  }

  getPrice(plan){
    switch (plan) {
      case "starter":
        return "9.90";
        break;
      case "maker":
        return "24.90";
        break;
      case "professional":
        return "149.90";
        break;
      default:

    }
  }

  register(plan){
    this.setState({registering: true});

    let { name, email, pass, passRepeat, domain } = this.state;

    let issue = false;

    if(!name){
      issue = "Your name can't be empty";
    }

    if(!email || !email.includes("@")){
      issue = "Your email should be in the right format";
    }

    if(!pass){
      issue = "Your password can't be empty";
    }

    if(domain && !domain.includes(".")){
      issue = "Your input domain is not in a domain format";
    }

    if(issue){
      this.setState({issue, registering: false});
      if(typeof window != "undefined"){
        window.scrollTo(0, 0);
      }
    }else{
      this.setState({issue: null});
      fetch("https://api.svgapi.com/register.php", {
        body: `name=${name}&email=${email}&pass=${pass}&domain=${domain}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }).then(res => res.json()).then(res => {
        console.log("Server Response", res);
        if(res == "registered"){
          if(typeof window != "undefined"){
            window.Paddle.Checkout.open({
              product: this.getPlanId(plan),
              email: this.state.email
            });
          }
        }else if(res == "not_defined"){
          this.setState({issue: "One or more field can't be empty", registering: false});
          if(typeof window != "undefined"){
            window.scrollTo(0, 0);
          }
        }else if(res == "email_already"){
          this.setState({issue: "The email is already in use in our system, please login to your panel if you are already registered", registering: false});
          if(typeof window != "undefined"){
            window.scrollTo(0, 0);
          }
        }else {
          this.setState({issue: "Something went wrong from the server end, please contact us", registering: false});
          if(typeof window != "undefined"){
            window.scrollTo(0, 0);
          }
        }
      })
    }
  }

  render(){
    let plan = this.props.location.search.split("=")[1];
    if(!plan){
      plan = "starter";
    }
    return (
      <Layout noFooter={true}>
        <SEO title="svgapi - Register" description="Register to svgapi admin panel with the choosen plan."/>
        <div className="register">
          <div className={this.state.registered ? "side registered" : "side"}>
            <div className="sideInner">
              <div className="header">
                <a href="/"><img src={require("../images/logo_white.svg")} className="logo"/></a>
              </div>
              <div className="sideInfo">
                <h1>Register with {plan == "professional" ? "Pro" : plan[0].toUpperCase() + plan.substr(1)} Plan</h1>
                {plan == "starter" &&
                  <p>You will get {this.getCalls(plan)} calls/mo for free for the first month, then <strong>${this.getPrice(plan)}</strong> monthly.</p>
                }
                {plan != "starter" &&
                  <p>You will get {this.getCalls(plan)} calls/mo for every month at <strong>${this.getPrice(plan)}</strong> monthly.</p>
                }
                <p>With unlimited CDN calls and domains.</p>
              </div>

              <div className="registeredInfo">

                <h1>Registration Complete!</h1>
                <p>Your transaction has been completed successfully. We have emailed you details of your order.</p>
                <p>Now, you can login to your panel.</p>

                <a href="https://panel.svgapi.com/"><div className="button" style={{backgroundColor: "#fff", color: "#141414"}}>Click Here to Login</div></a>


              </div>

              <Link to="/pricing"><div className="backText"><span>⟵</span> Change Plans</div></Link>
            </div>
          </div>
          <div className="sideHolder"></div>
            <div className="form">
              <div className="formInner">
                {this.state.issue &&
                  <div className="notice">
                    <strong>An error occurred!</strong><br/>
                    {this.state.issue}.
                  </div>
                }
                <form>
                  <div className="labelCarrier">
                    <div className="label">
                    Your Name
                    </div>

                    <input type="text" placeholder="Jhon Doe" onChange={(e) => this.setState({name: e.target.value})} required/>
                    <div className="labelDesc">Enter a company name if applicable.</div>
                  </div>

                  <div className="labelCarrier">
                    <div className="label">
                    E-mail
                    </div>

                    <input type="email" placeholder="jhon@company.com" autoComplete={"username"} onChange={(e) => this.setState({email: e.target.value})} required/>
                    <div className="labelDesc">Your invoices will be sent here.</div>
                  </div>

                  <div className="labelCarrier">
                    <div className="label">
                    Password
                    </div>

                    <input type="password" placeholder="Password" onChange={(e) => this.setState({pass: e.target.value})} autoComplete={"new-password"} required/>
                    <div className="labelDesc">Used when logging in the panel.</div>
                  </div>

                  <div className="labelCarrier">
                    <div className="label">
                    Website Domain
                    </div>

                    <input type="text" placeholder="mydomain.com" onChange={(e) => this.setState({domain: e.target.value})} />
                    <div className="labelDesc">You can add more in your panel</div>
                  </div>
                </form>
                <div className="labelCarrier">
                  We work with <em>paddle.com</em> for subscriptions, by registering for this service, you accept our reseller agent's <a href="https://paddle.com/legal-buyers/" target="_blank">Paddle Buyer Terms and Conditions</a>. Opens in a new tab.
                </div>
                {this.state.registering &&
                  <div className="button" onClick={() => this.register(plan)} style={{float: "none", width: 300, textAlign: "center", marginBottom: 10}}>Please wait...</div>
                }
                {!this.state.registering &&
                  <div className="button" onClick={() => this.register(plan)} style={{float: "none", width: 300, textAlign: "center", marginBottom: 10}}>Register with {plan == "professional" ? "Pro" : plan[0].toUpperCase() + plan.substr(1)} Plan</div>
                }
                <div className="labelDesc">You need to enter payment details in the popup.</div>
                <br/>
                <br/>
              </div>
            </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
